<template>
  <div class="login">

    <div class="contenedor" style="width:400px">

      <!-- Logo -->
      <div class="columna">
        <div class="conflex">
          <span :class="$cfg.app.bar.titulo_class">
            {{ $cfg.app.bar.titulo }}
            <span :class="$cfg.app.bar.subtitulo_class">{{
              $cfg.app.bar.subtitulo
            }}</span>
          </span>
        </div>
      </div>

      <!-- Texto -->
      <div class="columna">
        <span style="font-size:20px">{{' Iniciar sesión' }}</span>
      </div>
<!--
      <div class="columna">
        <div class="user">
          <v-text-field
            v-model="user"
            ref="user"
            outlined
            dense
            placeholder="Usuario"
            :append-icon="step=='pass'? '' : 'mdi-arrow-right'"
            @click:append="validateUser"
            @keydown.13="validateUser"
            @focus="step='user'">
          </v-text-field>
        </div>
      </div>
      <div class="columna">
        <div v-show="step=='userpass'">
          <v-text-field
            v-model="password"
            ref="pass"
            outlined
            dense
            placeholder="Password"
            append-icon="mdi-arrow-right"
            type="password"
            @click:append="validatePass"
            @keydown.13="validatePass">
          </v-text-field>
        </div>
      </div>
-->

      <!-- TextField Usuario -->
      <div class="columna">
        <div class="user">
          <v-text-field
            v-model="user"
            ref="user"
            outlined
            dense
            placeholder="Usuario"
            :append-icon="step=='userpass'? '' : 'mdi-arrow-right'"
            @click:append="validateUser"
            @keydown.13="validateUser"
            @focus="step='user'">
          </v-text-field>
        </div>
      </div>

      <!-- TextField Contraseña -->
      <div class="columna">
        <div v-show="step=='userpass'">
          <v-text-field
            v-model="password"
            ref="pass"
            outlined
            dense
            placeholder="Password"
            append-icon="mdi-arrow-right"
            type="password"
            @click:append="validateUserPass"
            @keydown.13="validateUserPass">
          </v-text-field>
        </div>
      </div>

    </div>

  </div>
</template>



<script>

  export default {
    data() {
      return {
        step:'user',
        user: "",
        password: ""
      };
    },

    created() {
      if (sessionStorage.getItem("login")) this.$router.push({ path: '/' });
    },


    methods: {

      showLockError(time) {
        this.$root.$alert.open(('No puede acceder hasta ' + new Date(time)), 'error');
      },

      showUserPassError(tipo) {
        let msg= tipo== 'user'? 'Usuario inválido' : 'Contraseña incorrecta';
        this.$root.$alert.open(msg, 'error');
      },

      setFocus(elem) {
        //this.$refs.pass.focus();
        //console.log(this.$refs.pass.$el);
        let inputE = this.$refs[elem].$el.querySelector('input');
        setTimeout(()=>{ inputE.select(); }, 100);
      },

      // compruebo si el usuario existe
      validateUser() {
        if (!this.user.length) return;

        this.step= "userpass";
        this.password='';

        //this.$refs.pass.focus();
        this.setFocus('pass');
      },

      async validateUser0() {

        if (!this.user.length) return;

        // chequeo usuario en la Api
        let args = {
          accion: "validateUser",
          tipo: "",
          api: "login",
          args: {
            user: this.user
          },
          vs: "1",
        };

        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        console.log('validateUser: ', apiResult);

        if (apiResult.sql.error) {
          // usuario NO válido
          // muestro error de usuario, elimino token de usuario y
          // habilito sólo el campo user del login
          sessionStorage.removeItem("username");
          this.step= "user";
          //this.$root.$alert.open(apiResult.sql.msg, 'error');
          alert(apiResult.sql.msg);
          return;
        }

        // el usuario es correcto
        // guardo el token de usuario en la sesión del navegador
        sessionStorage.setItem("username", apiResult.r[0].token);
        this.step= "userpass";
      },


      // termino el login si las validaciones de usuario y contraseña son correctas
      async validatePass() {

        if (!this.password.length) return;

        // valido usuario y contraseña
        let args = {
          accion: "validatePassword",
          tipo: "",
          api: "login",
          vs: "1",
          args: {
            user: sessionStorage.getItem("username"), // token
            pass: this.password
          },
        };

        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        console.log('validatePass: ', apiResult);

        if (apiResult.sql.error) {
          // contraseña NO válida
          // elimino token login (si lo tuviera), borro datos iniciales
          // y muestro mensaje de error
          this.$store.commit("logout");
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          //alert(apiResult.sql.msg);
          return;
        }

        // la contraseña es correcta
        // guardo el token en la sesión del navegador
        // elimino el token de usuario
        sessionStorage.removeItem("username");
        await this.$store.commit("set_authSuccess", apiResult.r[0].token);

        // cargo datos iniciales
        await this.cargaInicial();

        // obtengo los avisos de expedientes
        if (this.$root.$avisos_campana) this.$root.$avisos_campana.recuperaN();
      },

      // termino el login si las validaciones de usuario y contraseña son correctas
      async validateUserPass() {

          if (!this.user.length) return;
          if (!this.password.length) return;

          // valido usuario y contraseña
          let args = {
            accion: "validateUserPassword",
            tipo: "",
            api: "login",
            vs: "1",
            args: {
              user: this.user,
              pass: this.password
            },
          };

          let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
          console.log('validateUserPass: ', apiResult);

          if (apiResult.sql.error) {
            // contraseña NO válida
            // elimino token login (si lo tuviera), borro datos iniciales
            // y muestro mensaje de error
            this.$store.commit("logout");
            this.$root.$alert.open(apiResult.sql.msg, 'error');
            //alert(apiResult.sql.msg);
            return;
          }

          // la contraseña es correcta
          // guardo el token en la sesión del navegador
          // elimino el token de usuario
          sessionStorage.removeItem("username");
          await this.$store.commit("set_authSuccess", apiResult.r[0].token);

          // cargo datos iniciales
          await this.cargaInicial();

          // obtengo los avisos de expedientes
          //if (this.$root.$avisos_campana) this.$root.$avisos_campana.recuperaN();
      },


      async cargaInicial() {
        let apiResult= await this.$store.dispatch("ini/load_dataIni");

        let ruta='/';
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          ruta='login';
        }

        this.$router.push({ path: ruta });
      },

    },


    watch: {
      step() {
        console.log('watch step ', this.step);
        if (this.step!= 'pass') return;
        // pendiente: comprobar nextTick
        this.$refs.pass.focus();
      },

    }

  };

</script>



<style>

  .titulo span {
    font-size: 22px;
  }

  /* textfields global */
  .tf {
    padding: 0px 15px 0px 15px;
  }
  .tf input {
    font-size: 15px;
  }

  /* textfield particular */
  .tf .input_user,
  .tf .input_pass {
    padding-top: 35px;
  }
  .tf .input_captcha {
    padding-top: 20px;
  }

  /* captcha */
  .captcha {
    padding-top: 30px;
  }
  .captcha .letters {
    display: inline;
  }
  .captcha .reload {
    display: inline;
  }

  /* botones de acciones */
  .btn_next {
    text-align: center;
    padding: 25px 15px 25px 0px;
  }
  .btns_pass {
    display: inline-block;
    padding: 25px 0px 25px 0px;
  }
  .btns_pass .btn_before {
    display: inline-block;
    padding-right: 20px;
  }
  .btns_pass .btn_end {
    display: inline-block;
  }

</style>